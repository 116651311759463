<script setup>
const props = defineProps({
  isLoading: Boolean,
  text: String,
});
</script>

<template>
  <button :class="{ loading: props.isLoading }" :disabled="props.isLoading">
    {{ props.text }}
  </button>
</template>
