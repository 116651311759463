<script setup>
import { nextTick, ref, watch } from "vue";
import Result from "./common/FormResult.vue";
import Submit from "./common/FormSubmit.vue";
import useCookie from "../utils/cookies.js";
import useApi from "../utils/apiCalls.js";

const { getCookie, setCookie, delCookie } = useCookie();

const { apiGetRequest, apiPostRequest } = useApi();

// Get referrer
const urlParams = new URLSearchParams(window.location.search);
var urlParamEmail = urlParams.get("email");
var urlParamCode = urlParams.get("code");
var urlParamReferrer = urlParams.get("referrer");
var urlParamSignout = urlParams.get("signout");

// Refs
const inputPassword = ref(null);
watch(
  () => inputPassword.value,
  val => {
    if (val) {
      nextTick();
      inputPassword.value.focus();
    }
  }
);

// Process - existing JWT
const jwt = getCookie("jwt");
if (jwt) {
  // Signout
  if (urlParamSignout == "true") {
    delCookie("jwt");
    document.cookie = "jwt=null;domain=" + import.meta.env.VITE_APP_FLIGHTFOX_COOKIE_DOM + ";expires=Thu, 01 Jan 1970 00:00:00 GMT";
  } else {
    // Get user details
    apiGetRequest({
      path: "/users",
      onSuccess: data => {
        // Attempt to fix redirect loop
        document.cookie = "jwt=null;domain=" + import.meta.env.VITE_APP_FLIGHTFOX_COOKIE_DOM + ";expires=Thu, 01 Jan 1970 00:00:00 GMT";
        // Redirect to correct app
        setCookie("jwt", jwt); // Attempt to fix redirect loop
        if (urlParamReferrer) {
          if (data.is_admin || (data.is_expert && urlParamReferrer.includes(import.meta.env.VITE_APP_FLIGHTFOX_APP_EXPERT_URL)) || urlParamReferrer.includes(import.meta.env.VITE_APP_FLIGHTFOX_APP_CUSTOMER_URL)) {
            window.location.href = urlParamReferrer;
          } else {
            urlParamReferrer = null;
          }
        }

        if (!urlParamReferrer) {
          if (data.is_admin) {
            window.location.href = import.meta.env.VITE_APP_FLIGHTFOX_APP_ADMIN_URL;
          } else if (data.is_expert) {
            window.location.href = import.meta.env.VITE_APP_FLIGHTFOX_APP_EXPERT_URL;
          } else {
            window.location.href = import.meta.env.VITE_APP_FLIGHTFOX_APP_CUSTOMER_URL;
          }
        }
      },
      onError: error => {
        delCookie("jwt");
        sendError(error);
      },
    });
  }
}

// Form - generic
const formError = ref(null);
const formIsLoading = ref(false);

// Form - email
const formEmail = ref(import.meta.env.MODE === "development" ? "customer@flightfox.com" : null);
const formIsSignin = ref(false);
const email = () => {
  formIsLoading.value = true;
  apiGetRequest({
    path: `/signin?email=${formEmail.value}`,
    onSuccess: data => {
      if (data.is_success == true) {
        // Name
        formName.value = data.name;

        // Steps
        formIsRegister.value = false;
        formIsSignin.value = true;
      } else {
        // Steps
        formIsRegister.value = true;
        formIsSignin.value = false;
        formName.value = null;

        // Organization
        formOrganization.value = data.organization;
        formIsDomainRegistered.value = data.is_domain_registered;
      }

      // Operations
      formError.value = null;
      formIsLoading.value = false;
    },
    onError: error => {
      formIsSignin.value == false;

      // Operations
      formError.value = error;
      formIsLoading.value = false;

      // Logging
      sendError(error);
    },
  });
};
const cancel = () => {
  formError.value = null;
  formIsLoading.value = false;
  formIsLinkSent.value = false;
  formIsDomainRegistered.value = false;
  formIsOrganizationDenied.value = false;
  formIsPassword.value = true;
  formIsRegister.value = false;
  formIsSignin.value = false;
  formCode.value = null;
  formPassword.value = null;
};
watch(
  () => formEmail.value,
  val => {
    if (val) {
      formEmail.value.trim();
    }
  }
);

// Form - signin
const formCode = ref(null);
const formIsLinkSent = ref(false);
const formIsPassword = ref(true);
const formIsResetSent = ref(false);
const formName = ref(null);
const formPassword = ref(import.meta.env.MODE === "development" ? "flightfox" : null);
const signin = () => {
  formIsLoading.value = true;

  apiPostRequest({
    path: "/signin",
    data: {
      email: formEmail.value,
      password: formPassword.value ? formPassword.value : null,
    },
    onSuccess: success,
    onError: error => {
      // Operations
      formError.value = error;
      formIsLoading.value = false;

      // Logging
      sendError(error);
    },
  });
};
const link = () => {
  formIsLoading.value = true;
  apiGetRequest({
    path: `/signin-link?email=${formEmail.value}`,
    onSuccess: data => {
      // Operations
      formError.value = null;
      formIsLoading.value = false;
      formIsLinkSent.value = true;

      if (data !== void 0 && data != "") {
        formCode.value = "" + data;
      }
    },
    onError: error => {
      // Operations
      formError.value = error;
      formIsLoading.value = false;

      // Logging
      sendError(error);
    },
  });
};
const code = (isLink = false) => {
  formIsLoading.value = true;

  apiPostRequest({
    path: isLink ? `/signin?is-link=${isLink}` : "/signin",
    data: {
      email: formEmail.value,
      code: formCode.value ? formCode.value : null,
    },
    onSuccess: success,
    onError: error => {
      // Suggest to send link if exceeded
      const isExceeded = error.response && String(error.response.data).indexOf("exceeded") != -1;
      if (isExceeded) {
        cancel();
      }

      // Operations
      formError.value = error;
      formIsLoading.value = false;
      formCode.value = null;
      formPassword.value = null;

      // Logging
      sendError(error);
    },
  });
};
const reset = () => {
  formIsLoading.value = true;
  apiGetRequest({
    path: `/reset-link?email=${formEmail.value}`,
    onSuccess: (/*data*/) => {
      // Operations
      formError.value = null;
      formIsLoading.value = false;
      formIsResetSent.value = true;

      // Handle code redirect
      urlParamReferrer = `${import.meta.env.VITE_APP_FLIGHTFOX_APP_CUSTOMER_URL}/settings/customer/account`;
    },
    onError: error => {
      // Operations
      formError.value = error;
      formIsLoading.value = false;

      // Logging
      sendError(error);
    },
  });
};
const success = data => {
  const token = data.token;

  // Set token cookie
  setCookie("jwt", token);

  // Operations
  formError.value = null;

  // Set to true to stay loading until redirect
  formIsLoading.value = true;

  // Redirect to correct app
  if (urlParamReferrer) {
    window.location.href = urlParamReferrer;
  } else {
    if (data.is_admin) {
      window.location.href = import.meta.env.VITE_APP_FLIGHTFOX_APP_ADMIN_URL;
    } else if (data.is_expert) {
      window.location.href = import.meta.env.VITE_APP_FLIGHTFOX_APP_EXPERT_URL;
    } else {
      window.location.href = import.meta.env.VITE_APP_FLIGHTFOX_APP_CUSTOMER_URL;
    }
  }
};

// Form - register
const formIsDomainRegistered = ref(false);
const formIsOrganizationDenied = ref(false);
const formIsShowPersonal = ref(false);
const formIsRegister = ref(false);
const formOrganization = ref(null);
const register = () => {
  if (formOrganization.value && formOrganization.value.toLowerCase().includes("ciee")) {
    alert("You cannot register CIEE. If you are a CIEE participant, please visit https://flightfox.com/ciee to book your flight.");
    return;
  }

  formIsLoading.value = true;
  apiPostRequest({
    path: "/customers",
    data: {
      name: formName.value,
      email: formEmail.value,
      organization: formOrganization.value,
    },
    onSuccess: success,
    onError: error => {
      // Operations
      formError.value = error;
      formIsLoading.value = false;

      // Handle existing organization
      if (error.response.data.includes("Organization already registered")) {
        formIsOrganizationDenied.value = true;
      }

      // Logging
      sendError(error);
    },
  });
};

// Process - sign-in link
if (urlParamEmail && urlParamCode) {
  formIsLinkSent.value = true;
  formEmail.value = urlParamEmail;
  formCode.value = urlParamCode;
  code(true);
}

// Errors
const sendError = (/*error*/) => {
  // Correct message
  // var errMsg = "";
  // if (error.response) {
  //   errMsg = error.response.data;
  // } else {
  //   errMsg = error;
  // }
};
</script>

<template>
  <div id="welcome">
    <div id="logo">
      <img src="../assets/images/logo.svg" alt="Flightfox" />
    </div>
    <form v-if="!formIsSignin && !formIsRegister" method="post" @submit.prevent="email">
      <div class="email">
        <div class="form-section">
          <label class="instruction" for="email">Hi, please enter your email address.</label>
          <Result v-model:error="formError" />
          <input id="email" v-model="formEmail" type="email" name="email" placeholder="e.g. fox@company.com" autocomplete="username" @keyup.enter="email" />
        </div>
        <Submit class="block" :is-loading="formIsLoading" :text="'Next'" @click="email" />
      </div>
    </form>
    <form v-if="formIsSignin" method="post" @submit.prevent="signin">
      <div class="signin">
        <div v-if="!formIsLinkSent && !formIsResetSent" class="form">
          <div class="form-section">
            <div class="form-row">Hi {{ formName }}, please select a method to sign in.</div>
            <div class="form-row">
              <div>
                <div class="radiolabel" :class="{ on: formIsPassword === true }" @click="formIsPassword = true">
                  <div class="radio" />
                  <label>Enter a password</label>
                </div>
                <div class="radiolabel" :class="{ on: formIsPassword === false }" @click="formIsPassword = false">
                  <div class="radio" />
                  <label>Get a sign-in link via email</label>
                </div>
              </div>
            </div>
            <Result v-model:error="formError" />
            <div v-show="formIsPassword">
              <label for="password">Password<a @click="reset">Reset</a></label>
              <input id="password" ref="inputPassword" v-model="formPassword" type="password" name="password" placeholder="e.g. happytravels123" autocomplete="current-password" @keyup.enter="signin" />
            </div>
          </div>
          <div v-if="formIsPassword">
            <Submit class="block" :is-loading="formIsLoading" :text="'Sign In'" @click="signin" />
          </div>
          <div v-if="!formIsPassword">
            <Submit class="block" :is-loading="formIsLoading" :text="'Send link to ' + formEmail" @click="link" />
          </div>
        </div>
        <div v-if="formIsLinkSent || formIsResetSent" class="sent">
          <p class="please">Please click the link in your email</p>
          <div class="form-section">
            <label for="instruction">Alternatively, enter your 4-digit code from the email</label>
            <Result v-model:error="formError" />
            <input id="instruction" ref="inputCode" v-model="formCode" type="tel" class="code" autocomplete="off" maxlength="4" placeholder="XXXX" @keyup.enter="code" />
          </div>
          <Submit class="block" :is-loading="formIsLoading" text="Submit Code" @click="code" />
        </div>
        <div class="or">Made a mistake? <a @click="cancel">Enter your email again</a></div>
      </div>
    </form>
    <div v-if="formIsRegister" class="register">
      <div class="form">
        <h2>New Account</h2>
        <Result v-model:error="formError" />
        <div v-if="formIsOrganizationDenied" class="denied">
          <p class="existing">
            Please ask your company for a user account or
            <a @click="cancel">enter an email address</a> with the company domain name.
          </p>
        </div>
        <div class="form-section">
          <div class="typ">
            <p>Type of account</p>
            <div class="radiolabel" :class="{ on: !formIsShowPersonal }" @click="formIsShowPersonal = false">
              <div class="radio" />
              <label>Organization</label>
            </div>
            <div class="radiolabel" :class="{ on: formIsShowPersonal }" @click="formIsShowPersonal = true">
              <div class="radio" />
              <label>Individual</label>
            </div>
          </div>
          <div v-if="formIsShowPersonal" class="pers">Flightfox no longer offers an individual travel booking service. We now also require approval for all organization accounts. If you register an organization, we will need to discuss your team's travel needs before activating your account.</div>
        </div>
        <div v-if="!formIsShowPersonal" class="form-section">
          <div class="form-row">
            <div class="form-col">
              <label for="org">{{ formIsDomainRegistered ? "Your" : "New" }} organization name</label>
              <input id="org" v-model="formOrganization" type="text" :disabled="formIsDomainRegistered" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <label for="email">Your work email<a @click="cancel">Change</a></label>
              <input id="email" v-model="formEmail" type="text" disabled />
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <label for="name">Your full name</label>
              <input id="name" v-model="formName" type="text" />
            </div>
          </div>
        </div>
        <Submit v-if="!formIsShowPersonal" class="block" :is-loading="formIsLoading" text="Register" @click="register" />
        <p class="existing">
          {{ formIsDomainRegistered ? "Not a new account?" : "Existing member?" }}
          <a @click="cancel">Enter email again</a>
        </p>
      </div>
      <div v-if="!formIsDomainRegistered && !formIsOrganizationDenied" class="sales">
        <h2>Contact Sales</h2>
        <p class="instruction">To discuss your travel needs and how we can help with your team's travel, please get in touch.</p>
        <ul>
          <li>
            Arrange a demo:
            <a href="mailto:sales@flightfox.com">sales@flightfox.com</a>
          </li>
          <li>
            Corporate travel:
            <a href="mailto:sales@flightfox.com">sales@flightfox.com</a>
          </li>
          <li>
            Group travel:
            <a href="mailto:groups@flightfox.com">groups@flightfox.com</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="less">
#welcome {
  padding: 4rem 2rem;

  #logo {
    text-align: center;
    margin-bottom: 2rem;

    img {
      height: 1.5rem;
    }
  }
  /*prevent autofill style changes*/
  input[type="email"]:-webkit-autofill,
  input[type="email"]:-webkit-autofill:hover,
  input[type="email"]:-webkit-autofill:focus,
  input[type="email"]:-webkit-autofill:active {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-text-fill-color: @dark! important;
    font-size: 100% !important;
    font-family: roboto, sans-serif !important;
  }

  /*prevent autofill style changes*/
  input[type="email"]:-webkit-autofill,
  input[type="email"]:-webkit-autofill:hover,
  input[type="email"]:-webkit-autofill:focus,
  input[type="email"]:-webkit-autofill:active {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-text-fill-color: @dark! important;
    font-size: 100% !important;
    font-family: roboto, sans-serif !important;
  }

  .instruction {
    margin-bottom: 1rem;
  }

  .or {
    margin-top: 2rem;
  }

  .please {
    display: block;
    background: @primary;
    border-radius: 2rem;
    color: @white;
    margin-bottom: 2rem;
    padding: 0.5rem 2rem;
    text-align: center;
  }

  label {
    a {
      float: right;
    }
  }

  .email,
  .signin {
    background: @white;
    border-radius: 1rem;
    box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    margin: auto;
    max-width: 27rem;
    padding: 2rem;
    width: 100%;
  }

  .signin {
    .radiolabel {
      margin-bottom: 0.5rem;
    }

    .radiolabel:last-child {
      margin-bottom: 0;
    }
  }

  .sent {
    .code {
      -moz-appearance: textfield;
      appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .register {
    box-sizing: border-box;
    margin: auto;
    max-width: 27rem;
    width: 100%;

    input {
      background: @white;
    }

    .typ {
      display: flex;

      p {
        flex: 1;
      }

      .strike {
        text-decoration: line-through;
      }
    }

    .pers {
      background: @secondary;
      border-radius: 0.25rem;
      color: @white;
      margin-top: 1rem;
      padding: 1rem;
    }

    .sales {
      border-top: 0.0625rem dashed @primary;
      margin-top: 2rem;
      padding-top: 2rem;

      li {
        margin: 0 0 0.5rem 1.25rem;
      }
    }

    button {
      margin: 1rem 0 2rem;
    }

    .check {
      display: block;
      background: @primary;
      border-radius: 2rem;
      color: @white;
      margin-bottom: 2rem;
      padding: 0.5rem 2rem;
      text-align: center;
    }

    .type {
      margin-bottom: 2rem;

      label {
        margin: 0;
      }

      .radiolabel:first-child {
        margin-bottom: 0.5rem;
      }
    }

    .denied {
      margin-bottom: 2rem;
    }
  }
}

@media screen and (min-width: 60em) {
  #welcome {
    .register {
      display: flex;
      justify-content: center;
      max-width: none;

      .form {
        max-width: 27rem;
        width: 100%;
      }

      .sales {
        border-top: none;
        border-left: 0.0625rem dashed @primary;
        margin: 0 0 0 4rem;
        max-width: 27rem;
        padding: 0 0 0 4rem;
        width: 100%;
      }
    }
  }
}
</style>
