<script setup>
import { computed, ref, watch } from "vue";
import Result from "../common/FormResult.vue";
import Submit from "../common/FormSubmit.vue";

import useCookie from "../../utils/cookies.js";
import useApi from "../../utils/apiCalls.js";
import { useRoute } from "vue-router";

const route = useRoute();
const { getCookie, setCookie } = useCookie();
const { apiGetRequest, apiPostRequest } = useApi();

// Category
var category = route.meta.category;
const label = ref(null);
const org = ref(null);
const orgLabel = ref(null);
const categorySet = () => {
  // Label
  if (category == "ciee-hssa") {
    label.value = "CIEE High School Summer Abroad";
  } else if (category == "ciee-hsa") {
    label.value = "CIEE High School Semester Abroad";
  } else if (category == "ciee-gya") {
    label.value = "CIEE Gap Year Abroad";
  } else if (category == "ciee-fya") {
    label.value = "CIEE First Year Abroad";
  } else if (category == "medlife-college") {
    label.value = "MEDLIFE College";
  } else if (category == "medlife-hs") {
    label.value = "MEDLIFE High School";
  } else if (category == "montana") {
    label.value = "Montana State University";
  } else if (category == "rustic-staff") {
    label.value = "Rustic Pathways Staff";
  }

  // Org
  org.value = category.indexOf("-") > 0 ? category.substring(0, category.indexOf("-")) : category;
  if (org.value == "ciee") {
    orgLabel.value = "CIEE";
  } else if (org.value == "medlife") {
    orgLabel.value = "MEDLIFE";
  } else if (org.value == "montana") {
    orgLabel.value = "Montana State University";
  } else if (org.value == "rustic") {
    orgLabel.value = "Rustic Pathways";
  }
};
categorySet();
watch(
  () => category,
  () => {
    categorySet();
  }
);

// Form
var formError = ref(null);
var formIsLoading = ref(false);
const formYears = (isReverse, limit) => {
  var i;
  var list = [];
  const year = new Date().getFullYear();
  if (isReverse) {
    for (i = year; i >= year - limit; i--) {
      list.push(i);
    }
  } else {
    for (i = year; i <= year + limit; i++) {
      list.push(i);
    }
  }
  return list;
};

// Auth
const jwt = getCookie("jwt");
const usesExistingTripId = ref(null);
const userIsExistingTrip = ref(false);
const userIsAnotherTrip = ref(false);
const userIsCieeAdmin = ref(false);
const loadUser = () => {
  apiGetRequest({
    path: "/landing/user",
    onSuccess: data => {
      if (data.id > 0) {
        loadExistingTrip(data.email);
      }
      userIsCieeAdmin.value = data.is_ciee_admin;
    },
    onError: error => {
      alert(error);
    },
  });
};
function loadExistingTrip(email) {
  if (userIsAnotherTrip.value == true) {
    return;
  }

  apiGetRequest({
    path: `/organization-programs/${category}/is-existing/${email}`,
    onSuccess: data => {
      usesExistingTripId.value = data;
      if (usesExistingTripId.value > 0) {
        userIsExistingTrip.value = true;
        window.scrollTo(0, 0);
      }
    },
    onError: error => {
      alert(error);
    },
  });
}
function userVisitTrip(/*email*/) {
  formIsLoading.value = true;
  window.location.href = import.meta.env.VITE_APP_FLIGHTFOX_APP_CUSTOMER_URL + "/trips/" + usesExistingTripId.value;
}
if (jwt) {
  loadUser();
}

// Form - Step 1
const formIsSponsored = ref(false);
const formEmail = ref(null);
const formName = ref(null);
const formProgram = ref(null);
const formPrograms = ref([]);
const formProgramCity = ref(null);
const formProgramCities = ref(null);
const formProgramFull = ref({});
function loadProgram() {
  if (!formProgram.value) {
    return;
  }

  apiGetRequest({
    path: `/organization-program/${formProgram.value}`,
    onSuccess: data => {
      formProgramFull.value = data;
    },
    onError: error => {
      alert(error);
    },
  });
}
function loadPrograms() {
  apiGetRequest({
    path: `/organization-programs/${category}`,
    onSuccess: data => {
      formPrograms.value = data;
    },
    onError: error => {
      alert(error);
    },
  });
}
function loadProgramsByCity() {
  apiGetRequest({
    path: `/organization-programs/${category}/cities/${formProgramCity.value}`,
    onSuccess: data => {
      formPrograms.value = data;
    },
    onError: error => {
      alert(error);
    },
  });
}
function loadProgramCities() {
  apiGetRequest({
    path: `/organization-programs/${category}/cities`,
    onSuccess: data => {
      formProgramCities.value = data;
    },
    onError: error => {
      alert(error);
    },
  });
}
if (org.value == "medlife") {
  loadProgramCities();
} else {
  loadPrograms();
}

const tripType = computed(() => {
  if (formTypeIsGroup.value === true) {
    return "Chaperoned";
  } else if (formTypeIsGroup.value === false) {
    return "Individual";
  } else {
    return "Undecided";
  }
});
watch(
  () => formProgramCity.value,
  val => {
    if (val) {
      loadProgramsByCity();
    }
  }
);
watch(
  () => formProgram.value,
  val => {
    if (val) {
      loadProgram();
    }
  }
);

// Form - Step 2
const formHomeAirportFrom = ref(null);
const formHomeAirportRtIsDiff = ref(false);
const formHomeAirportIsDateDiff = ref(false);
const formHomeAirportTo = ref(null);
const formHomeAirportDateDiff = ref(null);
const formPax = ref(category == "medlife-college" ? null : 1);
const formPaxIsShow = computed(() => {
  return category == "medlife-college";
});

// Form - Step 3
const formPassportFirstName = ref(null);
const formPassportMiddleName = ref(null);
const formPassportLastName = ref(null);
const formDateOfBirthYear = ref(null);
const formDateOfBirthMonth = ref(null);
const formDateOfBirthDay = ref(null);
const formSex = ref(null);
const formPassportNumber = ref(null);
const formPassportCountry = ref(null);
const formPassportExpiryYear = ref(null);
const formPassportExpiryMonth = ref(null);
const formPassportExpiryDay = ref(null);
const formSeatPreference = ref(null);
const formDietaryRequirements = ref(null);
const formOther = ref(null);
const formCountries = ref([]);
function loadCountries() {
  apiGetRequest({
    path: "/countries",
    onSuccess: data => {
      formCountries.value = data;
      formCardAddressCountry.value = "United States";
    },
    onError: error => {
      alert(error);
    },
  });
}
loadCountries();
const datePassportExpiry = computed(() => {
  if (formPassportExpiryYear.value == null || formPassportExpiryMonth.value == null || formPassportExpiryDay.value == null) {
    return null;
  }
  return new Date(Date.UTC(formPassportExpiryYear.value, formPassportExpiryMonth.value - 1, formPassportExpiryDay.value, 0, 0, 0));
});
const dateDateOfBirth = computed(() => {
  if (formDateOfBirthYear.value == null || formDateOfBirthMonth.value == null || formDateOfBirthDay.value == null) {
    return null;
  }
  return new Date(Date.UTC(formDateOfBirthYear.value, formDateOfBirthMonth.value - 1, formDateOfBirthDay.value, 0, 0, 0));
});
const formUMName = ref(null);
const formUMPhone = ref(null);
const formUMAddress = ref(null);
const isUM = computed(() => {
  var limit;
  if (formProgramFull.value && formProgramFull.value.date_start) {
    limit = new Date(formProgramFull.value.date_start);
    limit.setDate(limit.getDate() - 2);
  } else {
    var now = new Date();
    limit = new Date(now.getFullYear(), "06", "01");
  }
  limit.setFullYear(limit.getFullYear() - 15);
  return dateDateOfBirth.value > limit;
});

// Form - Step 4
const isWhyChap = ref(false);
const isCheapest = ref(false);
const formTypeIsGroup = ref(true);
const formCollabName1 = ref(null);
const formCollabEmail1 = ref(null);
const formCollabName2 = ref(null);
const formCollabEmail2 = ref(null);
const formCollabName3 = ref(null);
const formCollabEmail3 = ref(null);
const collaborators = computed(() => {
  var result = [];
  if (formCollabName1.value != "" && formCollabEmail1.value != "") {
    result.push({ name: formCollabName1.value, email: formCollabEmail1.value });
  }
  if (formCollabName2.value != "" && formCollabEmail2.value != "") {
    result.push({ name: formCollabName2.value, email: formCollabEmail2.value });
  }
  if (formCollabName3.value != "" && formCollabEmail3.value != "") {
    result.push({ name: formCollabName3.value, email: formCollabEmail3.value });
  }
  return result;
});
const formTypeIsShow = computed(() => {
  return category == "ciee-hssa" || category == "medlife-hs";
});
const formCollabIsShow = computed(() => {
  return category != "rustic-staff";
});
const formCollabIsOptional = computed(() => {
  return category == "medlife-college" || category == "montana" || category == "rustic-staff";
});

// Form - Step 5
const formCardName = ref(null);
const formCardNumber = ref(null);
const formCardExpiryMonth = ref(null);
const formCardExpiryYear = ref(null);
const formCardSecurity = ref(null);
const formCardAddressStreet = ref(null);
const formCardAddressCity = ref(null);
const formCardAddressState = ref(null);
const formCardAddressZip = ref(null);
const formCardAddressCountry = ref(null);
const formCardIsShow = computed(() => {
  return category != "ciee-hsa" && org.value != "rustic" && formIsSponsored.value == false;
});

// FAQ
const faqOpenI = ref(null);
const faqOpen = i => {
  faqOpenI.value = faqOpenI.value == i ? null : i;
};

// Validate
const validate = computed(() => {
  var errors = [];

  // Step 1
  if (formProgram.value == null) {
    errors.push("Please select a program");
  }
  if (formEmail.value == null) {
    errors.push("Please enter a participant email");
  } else if (formEmail.value && !validateEmail(formEmail.value)) {
    errors.push("Please enter a valid participant email");
  }

  if (formPax.value != 1 || formIsSponsored.value == true) {
    if (formName.value == null) {
      errors.push("Please enter a participant full name");
    }
  }

  // Step 2
  if (formHomeAirportFrom.value == null) {
    errors.push("Please enter a home airport");
  }

  // Step 3
  if (formPax.value == 1 && formIsSponsored.value == false) {
    if (formPassportFirstName.value == null || formPassportLastName.value == null) {
      errors.push("Please enter a passport first and last name");
    }
    if (formDateOfBirthYear.value == null || formDateOfBirthMonth.value == null || formDateOfBirthDay.value == null) {
      errors.push("Please enter a date of birth");
    }
    if (formSex.value == null) {
      errors.push("Please enter a gender");
    }
    if (isUM.value && (!formUMName.value || !formUMPhone.value || !formUMAddress.value)) {
      errors.push("Please enter a guardian name, phone and address");
    }
  }

  // Step 4
  // If Mandatory
  if (!formCollabIsOptional.value && (!formCollabName1.value || !formCollabEmail1.value)) {
    errors.push("Please enter a name and email for collaborator 1");
  }
  if ((!formCollabName1.value && formCollabEmail1.value) || (formCollabName1.value && !formCollabEmail1.value)) {
    errors.push("Please enter a name and email for collaborator 1");
  }
  if ((!formCollabName2.value && formCollabEmail2.value) || (formCollabName2.value && !formCollabEmail2.value)) {
    errors.push("Please enter a name and email for collaborator 2");
  }
  if ((!formCollabName3.value && formCollabEmail3.value) || (formCollabName3.value && !formCollabEmail3.value)) {
    errors.push("Please enter a name and email for collaborator 3");
  }
  if (formCollabEmail1.value && !validateEmail(formCollabEmail1.value)) {
    errors.push("Please enter a valid email for collaborator 1");
  }
  if (formCollabEmail2.value && !validateEmail(formCollabEmail2.value)) {
    errors.push("Please enter a valid email for collaborator 2");
  }
  if (formCollabEmail3.value && !validateEmail(formCollabEmail3.value)) {
    errors.push("Please enter a valid email for collaborator 3");
  }

  // Step 5
  if (formCardIsShow.value == true) {
    if (formCardName.value == null) {
      errors.push("Please enter a credit card name");
    }
    if (formCardNumber.value == null) {
      errors.push("Please enter a credit card number");
    }
    if (formCardExpiryMonth.value == null || formCardExpiryYear.value == null) {
      errors.push("Please enter a credit card expiry");
    }
    if (formCardSecurity.value == null) {
      errors.push("Please enter a credit card security number");
    }
    if (formCardAddressStreet.value == null) {
      errors.push("Please enter a credit card address street");
    }
    if (formCardAddressCity.value == null) {
      errors.push("Please enter a credit card address city");
    }
    if (formCardAddressState.value == null) {
      errors.push("Please enter a credit card address state");
    }
    if (formCardAddressZip.value == null) {
      errors.push("Please enter a credit card address zip code");
    }
    if (formCardAddressZip.value == null) {
      errors.push("Please enter a credit card address country");
    }
  }

  return errors;
});

const currentOrganizationId = () => {
  const sel = formPrograms.value.find(p => p.id === formProgram.value);
  return sel ? sel.organization_id : null;
};

const saveData = () => {
  var dt = {
    // Form - Step 1
    category: category,
    email: formEmail.value,
    name: formIsSponsored.value ? formName.value : formPassportFirstName.value + " " + formPassportLastName.value,
    organization_id: currentOrganizationId(),
    organization_program_id: formProgram.value,
    organization_program_type: tripType.value,
    is_sponsored: formIsSponsored.value,

    // Form - Step 2
    home_airport_from: formHomeAirportFrom.value,
    home_airport_to: formHomeAirportTo.value,
    home_airport_dates_diff: formHomeAirportDateDiff.value,

    // Form - Step 3
    // UM
    um_name: formUMName.value,
    um_phone: formUMPhone.value,
    um_address: formUMAddress.value,

    // Form - Step 4
    collaborators: collaborators.value,
  };

  // Form - Step 3
  // Only add card if not sponsored
  if (formIsSponsored.value == false) {
    dt.pax_count = formPax.value;
    dt.traveler = {
      passport_first_name: formPassportFirstName.value,
      passport_middle_name: formPassportMiddleName.value,
      passport_last_name: formPassportLastName.value,
      passport_number: formPassportNumber.value,
      passport_country_id: formPassportCountry.value,
      passport_expiry: datePassportExpiry.value,
      date_of_birth: dateDateOfBirth.value,
      sex: formSex.value,
      dietary_requirements: formDietaryRequirements.value,
      other: formOther.value,
      email: formEmail.value,
      seat_preference: formSeatPreference.value,
      is_me: true,
    };
  }

  // Form - Step 5
  if (formCardIsShow.value == true) {
    dt.card = {
      number: formCardNumber.value ? formCardNumber.value.toString() : null,
      cvc: formCardSecurity.value ? formCardSecurity.value.toString() : null,
      expiry_month: formCardExpiryMonth.value ? formCardExpiryMonth.value.toString() : null,
      expiry_year: formCardExpiryYear.value ? formCardExpiryYear.value.toString() : null,
      full_name: formCardName.value,
      street: formCardAddressStreet.value,
      city: formCardAddressCity.value,
      state: formCardAddressState.value,
      label: "Participant card",
      country: formCardAddressCountry.value,
      zip_code: formCardAddressZip.value,
    };
  }
  return dt;
};

// Save
const save = () => {
  // Validate
  const errors = validate.value;
  if (errors.length > 0) {
    formError.value = Error(errors.join("<br />"));
    return;
  }

  formError.value = null;
  formIsLoading.value = true;

  apiPostRequest({
    path: "/landing/trip",
    data: saveData(),
    onSuccess: data => {
      // Set token cookie
      setCookie("jwt", data.token);

      // Redirect to trip
      window.location.href = import.meta.env.VITE_APP_FLIGHTFOX_APP_CUSTOMER_URL + "/trips/" + data.trip_id;

      formError.value = null;
      formIsLoading.value = false;
    },
    onError: error => {
      // Operations
      formError.value = error;
      formIsLoading.value = false;
    },
  });
};

// This is used to fix a bug with select list default option
const nullVar = null;

function validateEmail(email) {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}
</script>

<template>
  <div id="landing">
    <div id="main">
      <div class="logo">
        <img v-if="org == 'ciee'" class="partner ciee" src="../../assets/images/logo-ciee.png" :alt="orgLabel" />
        <img v-if="org == 'medlife'" class="partner medlife" src="../../assets/images/logo-medlife.png" :alt="orgLabel" />
        <img v-if="org == 'montana'" class="partner montana" src="../../assets/images/logo-montana.png" :alt="orgLabel" />
        <img v-if="org == 'rustic'" class="partner rustic" src="../../assets/images/logo-rustic.svg" :alt="orgLabel" />
        <img v-if="org != 'rustic'" class="ff" src="../../assets/images/logo.svg" alt="Flightfox" />
      </div>
      <h2>Book a flight for your {{ label }} program</h2>
      <div class="inner">
        <div v-if="userIsExistingTrip && !userIsAnotherTrip" class="frm">
          <div class="existing">
            <h3>Important!</h3>
            <p>We have detected you have an existing trip. Please do not submit this form again unless you want to book flights for multiple programs.</p>
            <p>
              <button @click="userVisitTrip">Visit your existing trip request here</button>
            </p>
            <p><a @click="userIsAnotherTrip = true">Or click here to book a flight for a second program</a>.</p>
          </div>
        </div>
        <div v-else class="frm">
          <Result v-model:error="formError" :is-no-scroll="true" />
          <div v-if="userIsCieeAdmin" class="form-section">
            <h3>Step 0. Admin Only</h3>
            <div class="form-row">
              <div class="checkboxlabel" :class="{ on: formIsSponsored }" @click="formIsSponsored = !formIsSponsored">
                <div class="checkbox" />
                <label>This is a sponsored trip (add sponsor as a collaborator below)</label>
              </div>
            </div>
          </div>
          <div class="form-section">
            <h3>Step 1. Your {{ orgLabel }} details</h3>
            <div class="infomsg">Please use the email address you use with {{ orgLabel }}. This helps track and connect your flights to your program. If you do not see your program listed, that means it has not yet been released for booking.</div>
            <div v-if="org != 'medlife'" class="form-row">
              <select v-model="formProgram" required>
                <option :value="nullVar" disabled>Select your program</option>
                <option v-for="p in formPrograms" :key="p.id" :value="p.id">
                  {{ p.name }}
                </option>
              </select>
            </div>
            <div v-if="org == 'medlife'" class="form-row">
              <select v-model="formProgramCity" required>
                <option :value="nullVar" disabled>Select your program city</option>
                <option v-for="c in formProgramCities" :key="c.name" :value="c.name">
                  {{ c.name }}
                </option>
              </select>
            </div>
            <div v-if="org == 'medlife' && formProgramCity" class="form-row">
              <select v-model="formProgram" required>
                <option :value="nullVar" disabled>Select your program name/date</option>
                <option v-for="p in formPrograms" :key="p.id" :value="p.id">
                  {{ p.name }}
                </option>
              </select>
            </div>
            <div class="form-row">
              <input v-model="formEmail" type="text" maxlength="100" required :placeholder="'Email address'" @blur="loadExistingTrip(formEmail)" />
            </div>
            <div v-if="formIsSponsored" class="form-row">
              <input v-model="formName" type="text" maxlength="100" required :placeholder="orgLabel + ' participant full name'" @blur="loadExistingTrip(formEmail)" />
            </div>
            <div v-if="!formCollabIsOptional" class="imp">This must be for the <u>participant</u>, not for a parent or guardian</div>
          </div>
          <div class="form-section">
            <h3>Step 2. Your home airport</h3>
            <div class="infomsg">Please enter your home airport code or name. If you can leave/depart from multiple airports, please list them in order of preference. We will attempt to find the cheapest combination for you.</div>
            <div class="form-row">
              <input v-model="formHomeAirportFrom" type="text" maxlength="100" required placeholder="Home airport (e.g. LAX)" />
            </div>
            <div class="form-row">
              <div class="form-gap">Do you want to return to a different airport?</div>
              <div class="radiolabel" :class="{ on: formHomeAirportRtIsDiff === true }" @click="formHomeAirportRtIsDiff = true">
                <div class="radio" />
                <label>Yes</label>
              </div>
              <div class="radiolabel" :class="{ on: formHomeAirportRtIsDiff === false }" @click="formHomeAirportRtIsDiff = false">
                <div class="radio" />
                <label>No</label>
              </div>
            </div>
            <div v-if="formHomeAirportRtIsDiff" class="form-row">
              <input v-model="formHomeAirportTo" type="text" maxlength="100" required placeholder="Return airport (e.g. DEN)" />
            </div>
            <div class="form-row">
              <div class="form-gap">Do you want to extend your trip and travel on different dates?</div>
              <div class="radiolabel" :class="{ on: formHomeAirportIsDateDiff === true }" @click="formHomeAirportIsDateDiff = true">
                <div class="radio" />
                <label>Yes</label>
              </div>
              <div class="radiolabel" :class="{ on: formHomeAirportIsDateDiff === false }" @click="formHomeAirportIsDateDiff = false">
                <div class="radio" />
                <label>No</label>
              </div>
            </div>
            <div v-if="formHomeAirportIsDateDiff" class="form-row">
              <input v-model="formHomeAirportDateDiff" type="text" maxlength="100" required placeholder="Dates you want to travel" />
            </div>
            <div v-if="formPaxIsShow">
              <div class="form-row">How many people are traveling?</div>
              <select v-model="formPax" required>
                <option :value="nullVar" disabled>Select number of travelers</option>
                <option v-for="i in 250" :key="i" :value="i">{{ i }} {{ i == 1 ? "Person" : "People" }}</option>
              </select>
            </div>
          </div>
          <div class="form-section">
            <h3>Step 3. Your passport information</h3>
            <div v-if="formPax == 1 && !formIsSponsored">
              <div class="form-section">
                <h4>Mandatory Information</h4>
                <div class="infomsg">Please ensure the required information below is exactly the same as recorded on your passport. Any discrepancies can lead to the airline denying boarding. If you require an unaccompanied minor service, we will detect and handle this.</div>
                <div class="form-row">
                  <input v-model="formPassportFirstName" type="text" maxlength="25" required placeholder="First name" />
                </div>
                <div class="form-row">
                  <input v-model="formPassportMiddleName" type="text" maxlength="25" placeholder="Middle name/s" />
                </div>
                <div class="form-row">
                  <input v-model="formPassportLastName" type="text" maxlength="25" required placeholder="Last name" />
                </div>
                <div class="form-row">
                  <div class="form-row form-col">
                    <div class="form-col">
                      <select v-model="formDateOfBirthYear" class="year" required>
                        <option :value="nullVar" disabled>DOB year</option>
                        <option v-for="year in formYears(true, 100)" :key="year" :value="year">
                          {{ year }}
                        </option>
                      </select>
                    </div>
                    <div class="form-col">
                      <select v-model="formDateOfBirthMonth" class="month" required>
                        <option :value="nullVar" disabled>DOB month</option>
                        <option v-for="n in 12" :key="n" :value="n">
                          {{ n < 10 ? "0" + n : n }}
                        </option>
                      </select>
                    </div>
                    <div class="form-col">
                      <select v-model="formDateOfBirthDay" class="day" required>
                        <option :value="nullVar" disabled>DOB date</option>
                        <option v-for="day in 31" :key="day" :value="day">
                          {{ day }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-col">
                    <select v-model="formSex" required>
                      <option :value="nullVar" disabled>Gender</option>
                      <option value="f">Female</option>
                      <option value="m">Male</option>
                      <option value="o">Other</option>
                    </select>
                  </div>
                </div>
                <div class="imp">This must be <u>exactly</u> as written on the passport</div>
              </div>
              <div v-if="isUM" class="form-section">
                <h4>Unaccompanied Minor Information</h4>
                <div class="infomsg">Since your date of birth classifies you as an <u>unaccompanied minor</u>, we must request your parent/guardian information. Please refer to the {{ orgLabel }} Unaccompanied Minor Information (top-right of this page).</div>
                <div class="form-row">
                  <div class="form-col">
                    <input v-model="formUMName" type="text" maxlength="255" placeholder="Guardian full name" />
                  </div>
                  <div class="form-col">
                    <input v-model="formUMPhone" type="text" maxlength="255" placeholder="Guardian phone number" />
                  </div>
                </div>
                <div class="form-row">
                  <input v-model="formUMAddress" type="text" maxlength="255" placeholder="Guardian address (street, city, state)" />
                </div>
                <div class="imp">This must be <u>exactly</u> as written on the passport</div>
              </div>
              <div class="form-section">
                <h4>Optional Information</h4>
                <div class="infomsg">Please note that we will do our best to secure your seat preference, but often securing a seat costs a fee with the airline and they can change your selection at any time, especially if we do not pay an additional fee.</div>
                <div class="form-row">
                  <select v-model="formSeatPreference">
                    <option :value="nullVar">Seat preference</option>
                    <option :value="false">Aisle</option>
                    <option :value="true">Window</option>
                  </select>
                </div>
                <div class="form-row">
                  <input v-model="formDietaryRequirements" type="text" maxlength="255" placeholder="Dietary requirements" />
                </div>
              </div>
            </div>
            <div v-else class="form-section">We will collect passport information for your group just before booking.</div>
          </div>
          <div v-if="formTypeIsShow || formCollabIsShow" class="form-section">
            <h3>Step 4. Your trip preferences</h3>
            <div v-if="formTypeIsShow" class="form-section">
              <h4>Type</h4>
              <div class="infomsg">Please note {{ orgLabel }} recommends you travel with a chaperone and your program group. You also have the option to travel unchaperoned (to save money), but you must arrive within predetermined times, which we will help coordinate.</div>
              <div class="form-row">
                <div class="radiolabel" :class="{ on: formTypeIsGroup === true }" @click="formTypeIsGroup = true">
                  <div class="radio" />
                  <label>Chaperoned (recommended)</label>
                </div>
              </div>
              <div class="form-row">
                <div class="radiolabel" :class="{ on: formTypeIsGroup === false }" @click="formTypeIsGroup = false">
                  <div class="radio" />
                  <label>Unchaperoned (cheapest)</label>
                </div>
              </div>
              <div class="form-row">
                <div class="radiolabel" :class="{ on: formTypeIsGroup === null }" @click="formTypeIsGroup = null">
                  <div class="radio" />
                  <label>Undecided</label>
                </div>
              </div>
              <div class="why">
                <a @click="isWhyChap = !isWhyChap">Why do chaperoned flights cost more?</a>
                <div v-if="isWhyChap" class="answer">
                  <p>{{ orgLabel }} and Flightfox work together to select chaperoned flights using strict requirements to reduce risks and delays. These flights must be direct where possible, meaning they are often not the cheapest option available.</p>
                  <p>Additionally, while we check hundreds of combinations from connecting cities to optimize for price, we have to find a balance that delivers a reasonable price to all participants.</p>
                  <p>
                    If you fly unchaperoned, we still must book within
                    {{ orgLabel }} requirements, but there are fewer requirements, which is why it is often cheaper.
                  </p>
                </div>
              </div>
              <div v-if="1 == 2" class="form-row">
                <div class="checkboxlabel" :class="{ on: isCheapest }" @click="isCheapest = !isCheapest">
                  <div class="checkbox" />
                  <label>I want the absolute cheapest flight using longer layovers and other techniques</label>
                </div>
              </div>
              <div class="why">
                <div v-if="isCheapest" class="answer">
                  <p>In selecting this option, we will attempt to save you money using all techniques publicly available. This will likely make your flight less convenient. Please only choose this option if you are highly price sensitive.</p>
                  <p>To reduce the price of flights, we may offer any of the following: split tickets on different airlines, longer layovers in connecting cities, buying airline miles direct from the airline, using skiplagged or kiwi itineraries, plus more.</p>
                  <p>As your travel partner, we will use our best endeavors to mitigate the inherent risks and inconveniences of selecting a cheaper option and will fully support your trip on travel day.</p>
                </div>
              </div>
            </div>
            <div v-if="formCollabIsShow" class="form-section">
              <h4>Collaborators</h4>
              <div class="infomsg">Please add the names and emails of any people, parents or guardians whom you would like to be involved in the booking of your flights. They will receive email notifications and booking confirmations.</div>
              <div class="form-row">
                <div class="form-col">
                  <input v-model="formCollabName1" type="text" required :placeholder="formCollabIsOptional ? 'Full name (optional)' : 'Full name'" />
                </div>
                <div class="form-col">
                  <input v-model="formCollabEmail1" type="text" required :placeholder="formCollabIsOptional ? 'Email address (optional)' : 'Email address'" />
                </div>
              </div>
              <div class="form-row">
                <div class="form-col">
                  <input v-model="formCollabName2" type="text" required placeholder="Full name (optional)" />
                </div>
                <div class="form-col">
                  <input v-model="formCollabEmail2" type="text" required placeholder="Email address (optional)" />
                </div>
              </div>
              <div class="form-row">
                <div class="form-col">
                  <input v-model="formCollabName3" type="text" required placeholder="Full name (optional)" />
                </div>
                <div class="form-col">
                  <input v-model="formCollabEmail3" type="text" required placeholder="Email address (optional)" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="formCardIsShow" class="form-section">
            <h3>Step 5. Your payment details</h3>
            <div class="infomsg">
              Please add a credit card for your travel bookings and service fee. We will not charge your booking or fee <u>until you approve an itinerary to book</u>. We process all credit card charges securely using <a href="https://stripe.com" target="_blank">Stripe</a>.
              <span v-if="org == 'medlife'">After you approve the flight itinerary, you will see two charges on your credit card; one for the USD 50 processing fee and one for your flight booking in full.</span>
            </div>
            <div class="form-row">
              <input v-model="formCardName" type="text" required placeholder="Name on card" />
            </div>
            <div class="form-row">
              <input v-model="formCardNumber" type="number" required placeholder="Card number" />
            </div>
            <div class="form-row">
              <div class="form-col">
                <select v-model="formCardExpiryMonth" required>
                  <option :value="nullVar" disabled>Expiry month</option>
                  <option v-for="m in 12" :key="m" :value="m">
                    {{ m < 10 ? "0" + m : m }}
                  </option>
                </select>
              </div>
              <div class="form-col">
                <select v-model="formCardExpiryYear" required>
                  <option :value="nullVar" disabled>Expiry year</option>
                  <option v-for="y in formYears(false, 10)" :key="y" :value="y">
                    {{ y }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <input v-model="formCardSecurity" type="text" required placeholder="Security code (3 or 4 digits)" maxlength="4" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <input v-model="formCardAddressStreet" type="text" required placeholder="Street address" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <input v-model="formCardAddressCity" type="text" required placeholder="City" />
              </div>
              <div class="form-col">
                <input v-model="formCardAddressState" type="text" required placeholder="State" maxlength="2" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <input v-model="formCardAddressZip" type="text" required placeholder="Zip/Postal code" maxlength="5" />
              </div>
              <div class="form-col">
                <select v-model="formCardAddressCountry" required>
                  <option :value="nullVar" disabled>Country</option>
                  <option v-for="c in formCountries" :key="c.id" :value="c.title">
                    {{ c.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <Submit class="block" :is-loading="formIsLoading" text="Submit" @click="save" />
        </div>
        <div class="faqs">
          <h3>Links</h3>
          <div v-if="org == 'ciee'" class="org-links">
            <ul>
              <li v-if="category == 'ciee-hsa'">
                <a href="https://ciee.my.salesforce.com/sfc/p/#i0000000ddow/a/3r000000EVPS/PhyYYSPFX5Ra6jBJJ4WPo.DR8ULGAmzciapPpUKoL8M " target="_blank">{{ orgLabel }} Flight Policies &amp; FAQ</a>
              </li>
              <li v-else-if="category != 'ciee-fya'">
                <a href="https://ciee.my.salesforce.com/sfc/p/i0000000ddow/a/5x000000ReK9/soHkdnauNa48W4UzHDRm71zEaNkOT0AjsHbGUoqIJDs" target="_blank">{{ orgLabel }} Flight Policies &amp; FAQ</a>
              </li>
              <li v-if="category != 'ciee-fya'">
                <a href="https://ciee.my.salesforce.com/sfc/p/i0000000ddow/a/5x000000ReJf/U0LPCnzRCjnf0T11Cecvq50eg61gY0z26dZaqRfoNkM" target="_blank">{{ orgLabel }} Unaccompanied Minor Information</a>
              </li>
              <li>
                <a href="https://ciee.my.salesforce.com/sfc/p/i0000000ddow/a/5x000000ReKE/VhT1lLy9wsX4M8g_Cdfp42COJLkg5yaOF1lMQNXNYPk" target="_blank">{{ orgLabel }} Flight Important Terms</a>
              </li>
            </ul>
          </div>
          <div v-if="org == 'medlife'" class="org-links">
            <ul>
              <li>
                <a href="https://app.hubspot.com/documents/21548837/view/724082556?accessId=d6f12f" target="_blank">{{ orgLabel }} Flight Policies &amp; FAQ</a>
              </li>
              <li>
                <a href="https://app.hubspot.com/documents/21548837/view/724082780?accessId=5dd559" target="_blank">{{ orgLabel }} Important Flight Terms</a>
              </li>
              <li>
                <a href="https://app.hubspot.com/documents/21548837/view/724082917?accessId=9760c0" target="_blank">{{ orgLabel }} Unaccompanied Minor Information</a>
              </li>
            </ul>
          </div>
          <div v-if="org == 'rustic'" class="org-links">
            <ul>
              <li>
                <a href="https://rb.gy/camgt9" target="_blank">{{ orgLabel }} Flight Policies &amp; FAQ</a>
              </li>
            </ul>
          </div>
          <h3>FAQ</h3>
          <div v-if="org != 'rustic'" class="faq" @click="faqOpen(1)">
            <p class="q"><a>Why book with Flightfox?</a><span class="icon">&#x2c5;</span></p>
            <p v-if="faqOpenI == 1" class="a">
              When you book with Flightfox, we are there for you every step of the way. To start, we guarantee the lowest prices on flights. Then once you are booked, we are here to support you 24/7 with any questions or issues. This is important for busy seasons and group flights when flight
              disruptions are common and it helps to have experts on call at all times of day.
            </p>
          </div>
          <div class="faq" @click="faqOpen(2)">
            <p class="q"><a>How we find the lowest price?</a><span class="icon">&#x2c5;</span></p>
            <p v-if="faqOpenI == 2" class="a">
              This is our specialty at Flightfox. We know all the tricks of the trade to guarantee you the lowest possible prices. Unlike other travel companies, we use both software and humans to search every provider, website and source; even popular consumer websites such as Kayak, Orbitz and
              Google.<br /><br />
              Keep in mind, {{ orgLabel }} has various restrictions on flights to ensure the best experience for everyone. You may find a lower price online for different flights, but they may arrive at the wrong time or connect in the wrong countries. We guarantee the lowest price for flights that
              meet {{ orgLabel }}'s requirements.
            </p>
          </div>
          <div class="faq" @click="faqOpen(3)">
            <p class="q"><a>How will we support your trip?</a><span class="icon">&#x2c5;</span></p>
            <p v-if="faqOpenI == 3" class="a">
              We have genuine travel experts based across five continents who provide 24/7 support in many languages. We have helped thousands of people navigate difficult situations; everything from flights missed to flights diverted and cancelled. We are just an email away and support you the
              entire way.
            </p>
          </div>
          <div v-if="org != 'rustic'" class="faq" @click="faqOpen(4)">
            <p class="q"><a>What do we charge?</a><span class="icon">&#x2c5;</span></p>
            <p v-if="faqOpenI == 4 && category != 'ciee-hsa'" class="a">
              Our $50 fee covers searching, booking, support, coordination and emergency response on travel days for each
              {{ orgLabel }} participant. If you need to change or cancel a flight, we also charge $50 per person since this often involves a lot of time on the phone negotiating airline fees to be as low as possible.<br /><br />
              CIEE covers the intital cost of searching, booking, support, coordination and emergency response on travel days for each
              {{ orgLabel }} participant. However, if you need to change or cancel a flight, we charge $50 per person since this often involves a lot of time on the phone negotiating airline fees to be as low as possible.
            </p>
          </div>
          <div class="faq" @click="faqOpen(5)">
            <p class="q"><a>What are the next steps?</a><span class="icon">&#x2c5;</span></p>
            <p v-if="faqOpenI == 5" class="a">
              Once you proceed through the steps on this page, we review your request and reply with a suggested itinerary at the lowest possible price. Once you approve, we will get you booked quickly to secure the lowest price. You will then receive your booking confirmation details and just need
              to arrive at the airport on travel day with your passport and bags.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
#landing {
  background: @white;

  .logo {
    margin: 0 auto 2rem;
    text-align: center;

    img {
      display: block;
      margin: auto;
    }

    .partner {
      margin-bottom: 1rem;
    }

    .ciee {
      height: 3rem;
    }

    .medlife {
      height: 5rem;
    }

    .montana {
      height: 5rem;
    }

    .rustic {
      height: 3rem;
    }

    .ff {
      height: 2rem;
    }
  }

  h2 {
    text-align: center;
  }

  .existing {
    border-left: 0.25rem solid @secondary;
    color: @secondary;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;

    button {
      margin: 2rem 0;
    }
  }

  .inner {
    border-radius: 1rem;
    margin: auto;
    max-width: 72rem;
  }

  .form-section {
    margin-bottom: 4rem;
  }

  .imp {
    color: @secondary;
  }

  .why {
    margin: 2rem 0;

    .answer {
      background: @light;
      margin-top: 1rem;
      padding: 2rem;
    }

    p {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .org-links {
    margin: 0 0 2rem 1rem;

    li {
      margin-bottom: 0.5rem;
    }
  }
}

@media screen and (min-width: 60em) {
  #landing {
    .logo {
      align-items: center;

      img {
        display: inline;
        margin: 0 1rem;
        vertical-align: middle;
      }

      .partner {
        margin-bottom: 0;
      }

      .ff {
        margin-top: 0.75rem;
      }
    }

    .inner {
      display: flex;
      margin: auto;

      .frm {
        flex: 1;
      }

      .faqs {
        flex: 0 0 35%;
      }
    }

    .faqs {
      border-left: 0.0625rem dashed @border;
      margin-left: 4rem;
      margin-top: 0;
      padding-left: 4rem;
    }
  }
}
</style>
