import { ref } from "vue";
import axios from "axios";
import useCookie from "./cookies.js";

const { getCookie } = useCookie();
const jwt = getCookie("jwt");

export default function (emit) {
  const apiRequest = async ({ path, method, data, onSuccess, onError }) => {
    try {
      const response = await axios({
        method: method,
        url: `${import.meta.env.VITE_APP_FLIGHTFOX_API_URL}${path}`,
        data: data,
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
        //withCredentials: path === "/signin",
      });

      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(response.data);
      }
    } catch (error) {
      if (onError && typeof onError === "function") {
        onError(error);
      } else if (emit) {
        emit("error", error);
      } else {
        alert(error);
      }
    }
  };

  const apiGetRequest = async ({ path, onSuccess, onError }) => {
    return apiRequest({ path, method: "get", onSuccess, onError });
  };
  const apiPostRequest = async ({ path, data, onSuccess, onError }) => {
    return apiRequest({ path, method: "post", data, onSuccess, onError });
  };
  const apiPutRequest = async ({ path, data, onSuccess, onError }) => {
    return apiRequest({ path, method: "put", data, onSuccess, onError });
  };
  const apiDeleteRequest = async ({ path, onSuccess, onError }) => {
    return apiRequest({ path, method: "delete", onSuccess, onError });
  };

  const formIsLoading = ref(false);
  const formIsSuccess = ref(false);
  const formError = ref(null);

  const apiFormRequest = async ({ path, method, data, onSuccess }) => {
    formIsLoading.value = true;
    formIsSuccess.value = false;
    formError.value = null;

    try {
      const response = await axios({
        method: method,
        url: `${import.meta.env.VITE_APP_FLIGHTFOX_API_URL}${path}`,
        data: data,
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      });

      formIsSuccess.value = true;
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(response.data);
      }
    } catch (err) {
      formIsSuccess.value = false;
      formError.value = err.response ? err.response.data : err.message;
    } finally {
      formIsLoading.value = false;
    }
  };

  return { formIsLoading, formIsSuccess, formError, apiGetRequest, apiPostRequest, apiPutRequest, apiDeleteRequest, apiFormRequest };
}
