export default function () {
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    return parts.length === 2 ? parts.pop().split(";").shift() : null;
  }
  function setCookie(name, value) {
    document.cookie = name + "=" + value + ";domain=" + import.meta.env.VITE_APP_FLIGHTFOX_COOKIE_DOMAIN + ";expires=Tue, 19 Jan 2038 00:00:00 GMT";
  }
  function delCookie(name) {
    document.cookie = name + "=null;domain=" + import.meta.env.VITE_APP_FLIGHTFOX_COOKIE_DOMAIN + ";expires=Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = name + "=null;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }

  return {
    getCookie,
    setCookie,
    delCookie,
  };
}
