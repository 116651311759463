import { createApp } from "vue";
import App from "./App.vue";
import Router from "./router/index";
import useApi from "./utils/apiCalls.js";

import "normalize.css";
import "./assets/styles/main.less";

const { apiPostRequest } = useApi();

const report = (errorPayload) => {
  console.error(errorPayload);
  // eslint-disable-next-line no-undef
  errorPayload.app = "flightfox-wel [" + __APP_VERSION__.substring(0, 16).replace("T", " ") + "]";
  apiPostRequest({ path: "/error-report", data: errorPayload, onSuccess: () => console.log("Error reported."), onError: (error) => console.error("Failed to report error:", error) });
};

try {

  const app = createApp(App);

  // Vue rendering errors
  app.config.errorHandler = (err, vm, info) => {
    report({
      message: err.message,
      stack: err.stack,
      component: vm.$options.name || "unknown component",
      info,
      url: window.location.href,
    });
  };

  //unhandled exceptions
  window.onerror = function (message, source, lineno, colno, error) {
    report({ message, source, lineno, colno, stack: error ? error.stack : "No stack trace", url: window.location.href });
  };

  //unhandled promise rejections
  window.onunhandledrejection = function (event) {

    let reason = "Unhandled promise rejection";
    let stack = "No stack trace";

    if (event.reason) {
      reason = event.reason.message || "Unhandled promise rejection";
      stack = event.reason.stack || "No stack trace";
    }

    report({ message: reason, stack: stack, url: window.location.href });
  };


  app.use(Router);
  app.mount("#app");
} catch (error) {
  report({ message: error.message, stack: error.stack, url: window.location.href });
}