<script setup>
import { computed, watch } from "vue";

const emit = defineEmits(["update:error", "update:isSuccess"]);

const props = defineProps({
  error: Error,
  isSuccess: Boolean,
  isNoScroll: Boolean,
});

const message = computed(() => {
  if (props.error != null) {
    var errMsg = "";
    if (props.error.response) {
      errMsg = props.error.response.data.toString();
    } else {
      errMsg = props.error.toString();
    }

    errMsg = errMsg.replace("Error: ", "");

    return errMsg;
  } else if (props.isSuccess === true) {
    return "Success!";
  }

  return "";
});

const scroll = () => {
  window.scrollTo(0, 0);
};

watch(
  () => props.error,
  (val) => {
    if (val) {
      scroll();
    }
  },
);
watch(
  () => props.isSuccess,
  () => {
    if (!props.isNoScroll) {
      scroll();
    }
  },
);
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="result">
    <div v-show="error || isSuccess" class="error" :class="{ pos: error == null }">
      <span class="msg" v-html="message" />
      <a
        class="icon"
        @click="
          emit('update:error', null);
          emit('update:isSuccess', false);
        "
        >&#x274c;</a
      >
    </div>
  </div>
</template>
