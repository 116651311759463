import { createRouter, createWebHistory } from "vue-router";
import LandingProgramTrip from "/src/components/landing/ProgramTrip.vue";
import Welcome from "/src/components/WelcomePage.vue";

const routes = [
  {
    path: "/",
    name: "welcome",
    component: Welcome,
    props: true,
  },
  {
    path: "/ciee",
    name: "landing-ciee",
    props: true,
    redirect: { name: "landing-ciee-hssa" },
  },
  {
    path: "/ciee-fya",
    name: "landing-ciee-fya",
    component: LandingProgramTrip,
    props: true,
    meta: {
      category: "ciee-fya",
    },
  },
  {
    path: "/ciee-gya",
    name: "landing-ciee-gya",
    component: LandingProgramTrip,
    props: true,
    meta: {
      category: "ciee-gya",
    },
  },
  {
    path: "/ciee-hssa",
    name: "landing-ciee-hssa",
    component: LandingProgramTrip,
    props: true,
    meta: {
      category: "ciee-hssa",
    },
  },
  {
    path: "/ciee-hsa",
    name: "landing-ciee-hsa",
    component: LandingProgramTrip,
    props: true,
    meta: {
      category: "ciee-hsa",
    },
  },
  {
    path: "/medlife-college",
    name: "landing-medlife-college",
    component: LandingProgramTrip,
    props: true,
    meta: {
      category: "medlife-college",
    },
  },
  {
    path: "/medlife-hs",
    name: "landing-medlife-hs",
    component: LandingProgramTrip,
    props: true,
    meta: {
      category: "medlife-hs",
    },
  },
  {
    path: "/montana",
    name: "landing-montana",
    component: LandingProgramTrip,
    props: true,
    meta: {
      category: "montana",
    },
  },
  {
    path: "/rustic-staff",
    name: "rustic-staff",
    component: LandingProgramTrip,
    props: true,
    meta: {
      category: "rustic-staff",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
